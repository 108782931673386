import classNames from "classnames";
import { t } from "i18n-js";
import { NavLink } from "react-router-dom";
import { Icon } from "@circle-react/components/shared/Icon";
import { useSupportWidgetContext } from "../SupportWidgetContext";
import "./styles.css";

const navLinkClassNames = isActive =>
  classNames(
    {
      "text-circle link-active": isActive,
      "text-default link-inactive": !isActive,
    },
    "relative flex flex-col gap-2 hover:text-circle focus:text-circle w-1/5 text-center",
  );

export const BottomMenu = () => {
  const { showMessages, unreadConversations } = useSupportWidgetContext();
  const { isDraftConversationPresent } = useSupportWidgetContext();

  return (
    <div className="flex h-[76px] w-full items-center justify-evenly text-xs">
      <NavLink
        to="/"
        className={navLinkClassNames}
        isActive={(match, location) => location.pathname === "/"}
      >
        <Icon type="home-v2" viewBox="0 0 16 16" />
        <span>{t("support_widget.bottom_menu.home")}</span>
      </NavLink>
      {(showMessages || isDraftConversationPresent) && (
        <NavLink to="/conversations" className={navLinkClassNames}>
          <Icon type="16-messages" viewBox="0 0 16 16" />
          {unreadConversations.length > 0 ? (
            <span className="absolute right-5 top-[-2px] h-2 w-2 rounded-full bg-blue-600" />
          ) : (
            isDraftConversationPresent && (
              <span className="bg-dark absolute right-5 top-[-2px] h-2 w-2 rounded-full" />
            )
          )}
          <span>{t("support_widget.bottom_menu.messages")}</span>
        </NavLink>
      )}
      <NavLink
        to="/space_groups"
        className={navLinkClassNames}
        isActive={(match, location) =>
          location.pathname.startsWith("/space_groups") ||
          location.pathname.startsWith("/spaces") ||
          (location.pathname.startsWith("/posts") &&
            // Production tutorials post ids, do not highlight tab for them
            !["848480", "3422440", "3701502"].includes(
              location.pathname.split("/")?.slice(-1)[0],
            ))
        }
      >
        <Icon type="question-mark" viewBox="0 0 24 24" />
        <span>{t("support_widget.bottom_menu.help")}</span>
      </NavLink>
      <NavLink
        to="/news"
        className={navLinkClassNames}
        isActive={(match, location) => location.pathname.startsWith("/news")}
      >
        <Icon type="announcements" size={20} />
        <span>{t("support_widget.bottom_menu.news")}</span>
      </NavLink>
      <NavLink
        to="/events"
        className={navLinkClassNames}
        isActive={(match, location) => location.pathname.startsWith("/events")}
      >
        <Icon type="support-widget-calendar" size={20} />
        <span>{t("support_widget.bottom_menu.events")}</span>
      </NavLink>
    </div>
  );
};
