import { memo } from "react";
import { Redirect } from "react-router-dom";
import { DeactivateAccountConfirmationModal } from "@/react/components/SettingsApp/DeactivateAccountConfirmationModal";
import { isUserSignedIn } from "@/react/helpers/punditUserHelpers";
import { useBoolean } from "@/react/hooks/utils/useBoolean";
import { SettingsNav } from "@circle-react/components/shared/SettingsNav";
import { usePunditUserContext } from "@circle-react/contexts";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";

const Component = () => {
  const [shouldShowDeactivateModal, toggleDeactivateModal] = useBoolean(false);

  const punditUserContext = usePunditUserContext();
  const { isLoading } = punditUserContext;

  if (isLoading) {
    return <SkeletonLoader variant="community-sidebar-v2" />;
  }

  if (!isUserSignedIn(punditUserContext)) {
    // Redirect to login page
    return <Redirect to="/" />;
  }

  return (
    <>
      <DeactivateAccountConfirmationModal
        show={shouldShowDeactivateModal}
        onClose={toggleDeactivateModal}
      />
      <div className="sidebar sidebar--left" data-testid="sidebar-left">
        <div
          className="fixed h-full w-full !max-w-[22.5rem]"
          data-draggable="false"
        >
          <SettingsNav />
        </div>
      </div>
    </>
  );
};

export const LeftSidebar = memo(Component);
