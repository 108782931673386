import { useEffect } from "react";
import { usePunditUserContext } from "@circle-react/contexts";

export const useDriftLink = () => {
  const { currentCommunity, currentCommunitySettings } = usePunditUserContext();

  const isDriftAtSupportWidgetEnabled =
    !!currentCommunitySettings?.drift_at_support_widget_enabled;

  const shouldShowDriftLink =
    currentCommunity.is_on_trial && isDriftAtSupportWidgetEnabled;

  useEffect(() => {
    if (shouldShowDriftLink) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.id = "hs-script-loader";
      script.async = true;
      script.defer = true;
      script.src = "//js.hs-scripts.com/9306053.js";
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [shouldShowDriftLink]);

  return { shouldShowDriftLink };
};
