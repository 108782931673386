import InfiniteScroll from "react-infinite-scroll-component";
import { chatInboxPath } from "@circle-react/helpers/urlHelpers";
import { Loader } from "@circle-react-uikit/Loader";
import { useChatList } from "../../hooks/useCommunityChatList";
import { ChatItem } from "./ChatItem";

export const ChatListComponent = () => {
  const { chats, isChatsLoading, hasNextPage, fetchNextPage } = useChatList();

  if (isChatsLoading) {
    return (
      <div className="h-[532px] 2xl:h-[592px]">
        <Loader />
      </div>
    );
  }

  return (
    <div id="scrollableDmsDiv" className="overflow-auto">
      <InfiniteScroll
        style={{ height: "100%", overflow: "hidden" }}
        scrollThreshold={0.8}
        next={fetchNextPage}
        hasMore={Boolean(hasNextPage)}
        dataLength={chats.length}
        scrollableTarget="scrollableDmsDiv"
        loader={<Loader center />}
      >
        {chats.map((chat: any) => (
          <ChatItem
            key={chat.id}
            chat={chat}
            path={chatInboxPath({ id: chat.id })}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};
