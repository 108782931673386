import { Icon } from "@circle-react-shared/Icon";
import { BlockEditSelector } from "../BlockEdit";
import { useFormBlocks } from "../Providers";

export const SidebarEditView = () => {
  const { removeBlockBeingEdited, blockBeingEdited } = useFormBlocks();

  if (!blockBeingEdited) return null;
  const { type, id } = blockBeingEdited;

  return (
    <div className="p-6">
      <button
        type="submit"
        className="text-dark -ml-2 mb-4 block h-7 w-7"
        onClick={removeBlockBeingEdited}
      >
        <Icon type="20-arrow-left" size={20} />
      </button>
      <BlockEditSelector type={type} id={id} />
    </div>
  );
};
