import { t } from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";
import { ConversationItem } from "../../ConversationItem";
import { DraftMessageButton } from "../../DraftMessageButton";
import { SkeletonLoader } from "../../SkeletonLoader";
import { useListConversations } from "../../queries";

export const ConversationsListView = () => {
  const { isLoading, data } = useListConversations();

  if (isLoading) {
    return (
      <>
        <SkeletonLoader rows={1} height="52px" wrapperClassName="!px-0" />
        {Array(4)
          .fill(null)
          .map((_, index) => (
            <div className="my-2" key={index}>
              <SkeletonLoader
                rows={1}
                height="20px"
                width="80%"
                loaderClassName="mb-2"
              />
              <SkeletonLoader
                rows={1}
                height="13px"
                width="25%"
                wrapperClassName="last:pb-0"
              />
            </div>
          ))}
      </>
    );
  }

  const conversations = data?.conversations || [];

  return (
    <div className="flex flex-col">
      <div className="border-primary bg-primary sticky top-0 z-10 flex h-[52px] shrink-0 items-center justify-center border-b px-3">
        <Typography.LabelMd weight="semibold">
          {t("support_widget.messages_screen.title")}
        </Typography.LabelMd>
      </div>
      <DraftMessageButton />
      {conversations.length === 0 ? (
        <div className="px-6 py-2">
          <Typography.BodyMd>
            {t("support_widget.no_messages_found")}
          </Typography.BodyMd>
        </div>
      ) : (
        conversations.map(conversation => (
          <ConversationItem key={conversation.id} conversation={conversation} />
        ))
      )}
    </div>
  );
};
