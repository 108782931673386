import { useMemo } from "react";
import { compact, flatten } from "lodash";

export const usePaywallPriceOptions = (paywallsListQuery: any) => {
  const getPaywallPriceAsOption = (price: any) => ({
    label: price.short_description,
    value: price.id,
    paywall_id: price.paywall_id,
  });

  const allPaywallPrices = useMemo(
    () =>
      paywallsListQuery?.data
        ? flatten(
            paywallsListQuery.data.map((paywall: any) =>
              paywall.prices.map((price: any) => ({
                paywall_id: paywall.id,
                currency_code: paywall.currency.code,
                trial_days: price.is_web_price ? paywall.trial_days : 0,
                ...price,
              })),
            ),
          )
        : [],
    [paywallsListQuery?.data],
  );

  const allInAppPaywallPriceOptions = useMemo(
    () =>
      compact(
        allPaywallPrices.map((price: any) =>
          price.is_in_app_price ? getPaywallPriceAsOption(price) : null,
        ),
      ),
    [allPaywallPrices],
  );
  const allWebPaywallPriceOptions = useMemo(
    () =>
      compact(
        allPaywallPrices.map((price: any) =>
          price.is_web_price ? getPaywallPriceAsOption(price) : null,
        ),
      ),
    [allPaywallPrices],
  );

  const hasWebPaywallPrices = useMemo(
    () => allWebPaywallPriceOptions?.length > 0,
    [allWebPaywallPriceOptions],
  );

  return {
    allPaywallPrices,
    allInAppPaywallPriceOptions,
    allWebPaywallPriceOptions,
    hasWebPaywallPrices,
  };
};
