import { PaymentProcessorStatus } from "@circle-react/components/Paywalls/Admin/PaywallsAdmin/constants";
import { usePaymentProcessorStatus } from "@circle-react/components/Paywalls/Admin/PaywallsAdmin/hooks/usePaymentProcessorStatus";
import { usePunditUserContext } from "@circle-react/contexts";
import { ConnectStripeContainer } from "./ConnectStripeContainer";
import { Header } from "./Header";
import { PaywallsEditForm } from "./PaywallsEditForm";
import { PaywallsEditFormProvider } from "./PaywallsEditFormProvider";

interface PaywallsEditProp {
  blockDataPath: string;
}

export const PaywallsEdit = ({ blockDataPath }: PaywallsEditProp) => {
  const { currentCommunity } = usePunditUserContext();
  const { paymentProcessorStatus } = usePaymentProcessorStatus({
    paymentProcessor: currentCommunity.payment_processor,
  });

  return (
    <PaywallsEditFormProvider blockDataPath={blockDataPath}>
      <div className="flex flex-col gap-y-6">
        <Header />
        {paymentProcessorStatus === PaymentProcessorStatus.ENABLED ? (
          <PaywallsEditForm />
        ) : (
          <ConnectStripeContainer />
        )}
      </div>
    </PaywallsEditFormProvider>
  );
};
