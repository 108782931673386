import { useMemo } from "react";
import { t } from "i18n-js";
import { compact } from "lodash";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import { PAYWALL_STATUS } from "@circle-react/helpers/paywallHelpers";
import { usePaywallList } from "@circle-react/hooks/paywalls/usePaywallList";

export const usePaywallOptions = () => {
  const { data: space } = useCurrentSpaceContext();
  const { paywallsListQuery } = usePaywallList({
    communityMemberId: null,
    status: [PAYWALL_STATUS.ACTIVE],
    spaceIds: [space?.id],
  });

  const getPaywallAsOption = (paywall: any) => {
    const isWebIncluded = paywall.prices.some(
      (price: any) => price?.is_web_price,
    );
    const isInAppIncluded = paywall.prices.some(
      (price: any) => price?.is_in_app_price,
    );

    const priceTypeStrings = compact([
      isWebIncluded ? t("web") : null,
      isInAppIncluded ? t("in_app") : null,
    ]);

    return {
      value: paywall.id,
      label: paywall.internal_name || paywall.display_name,
      description: t(
        "paywalls.lock_screen.sidebar.paywall_edit_form.fields.includes",
        {
          price_types: priceTypeStrings.join(", "),
          count: priceTypeStrings.length,
        },
      ),
    };
  };

  const allPaywallOptions = useMemo(
    () =>
      paywallsListQuery?.data
        ? paywallsListQuery.data.map(getPaywallAsOption)
        : [],
    [paywallsListQuery?.data],
  );
  const hasPaywalls = paywallsListQuery?.data?.length > 0;

  return {
    paywallsListQuery,
    getPaywallAsOption,
    allPaywallOptions,
    hasPaywalls,
  };
};
