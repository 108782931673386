import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "@circle-react-uikit/Loader";
import { MessageBox } from "../MessageBox";
import { Avatar } from "./Avatar";
import { ContentBlock } from "./ContentBlock";

interface ChatWindowProps {
  messages: any;
  createMessage: (data: any) => void;
  isDms: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean;
}

export const ChatWindow = ({
  messages,
  createMessage,
  isDms,
  fetchNextPage,
  hasNextPage,
}: ChatWindowProps) => (
  <div className="bg-primary flex h-[calc(100%-64px)] w-full flex-col justify-between">
    <div id="scrollableDmsDiv" className="overflow-auto">
      <InfiniteScroll
        style={{ height: "100%", overflow: "hidden" }}
        scrollThreshold={0.8}
        next={fetchNextPage}
        hasMore={hasNextPage}
        dataLength={messages.length}
        loader={<Loader center />}
      >
        {messages.map((message: any) => {
          const {
            created_at: createdAt,
            id,
            body,
            author_type: authorType,
            community_bot_profile: communityBotProfile,
            community_member: communityMember,
          } = message;

          const isUserMessage = authorType === "community_member";
          const authorName = isUserMessage
            ? communityMember.name
            : communityBotProfile.name;

          return (
            <div className="px-4 py-3" key={id}>
              <div className="flex w-full items-start justify-start gap-3">
                <div className="pt-1.5">
                  <Avatar message={message} />
                </div>
                <ContentBlock
                  authorName={authorName}
                  timestamp={createdAt}
                  body={body}
                />
              </div>
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
    {isDms && (
      <div className="px-4 py-3">
        <MessageBox onSubmit={createMessage} />
      </div>
    )}
  </div>
);
