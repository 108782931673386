import { sortBy } from "lodash";
import { flatten } from "lodash";
import { useInfiniteQuery, useMutation } from "react-query";
import {
  reactQueryGet,
  reactQueryPost,
} from "@circle-react/helpers/backendRequestHelpers";
import "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";

interface MessageListProps {
  id: number;
  params?: any;
}

export const useMessageList = ({
  id,
  params = { per_page: 20 },
}: MessageListProps) => {
  const fetchMessages = (page: number) =>
    reactQueryGet(
      internalApi.communityInbox.fetchMessages({
        id,
        params: {
          page,
          ...params,
        },
      }),
    );

  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery(
    ["community-inbox", "chat-room", "messages", id],
    ({ pageParam = 1 }) => fetchMessages(pageParam),
    {
      select: ({ pages = [] }) =>
        // RQ recommends to restructure your data that it still includes data.pages and data.pageParams properties - https://tanstack.com/query/v4/docs/react/guides/infinite-queries
        ({
          pages: flatten(pages.map(page => page.records)),
          pageParams: pages.map(page => page.page),
          pagesList: pages,
        }),
    },
  );

  const { mutateAsync: createMessage } = useMutation(
    (formValues: any) =>
      reactQueryPost(
        internalApi.communityInbox.createMessage({
          id,
        }),
        { ...formValues },
      ),
    {
      onSuccess: () => {
        //Update messages in store
      },
      onError: err => {
        console.error("Error while creating message", err);
      },
    },
  );

  const messages = sortBy(data?.pages || [], "created_at");
  return {
    messages,
    isMessagesLoading: isLoading,
    createMessage,
    hasNextPage,
    fetchNextPage,
  };
};
