import { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { usePaywallOptions } from "./usePaywallOptions";
import { usePaywallPriceOptions } from "./usePaywallPriceOptions";

const MAX_PAYWALLS_ALLOWED = 6;

export const usePaywallForm = (blockDataPath: string) => {
  const fieldNameRoot = `${blockDataPath}.paywalls`;
  const fieldArrayMethods = useFieldArray({
    name: fieldNameRoot,
    keyName: "_id",
  });

  const { watch } = useFormContext();

  useEffect(() => {
    if (fieldArrayMethods.fields.length === 0) {
      fieldArrayMethods.append({ id: undefined, paywall_highlighted: false });
    }
  }, [fieldArrayMethods.fields.length]);

  const { paywallsListQuery, allPaywallOptions, hasPaywalls } =
    usePaywallOptions();

  const {
    allPaywallPrices,
    allInAppPaywallPriceOptions,
    allWebPaywallPriceOptions,
    hasWebPaywallPrices,
  } = usePaywallPriceOptions(paywallsListQuery);

  const selectedPaywalls = watch(fieldNameRoot);
  const selectedPaywallIds = selectedPaywalls.map((paywall: any) => paywall.id);

  const onClickAddPaywall = () => {
    fieldArrayMethods.append({ id: undefined, paywall_highlighted: false });
  };

  const hasZeroPaywallsAvailable =
    allPaywallOptions.filter(
      (paywall: any) => !selectedPaywallIds.includes(paywall.value),
    ).length === 0;
  const hasSelectedMaximumPaywallsThreshold =
    selectedPaywalls.length >= MAX_PAYWALLS_ALLOWED;
  const areAllPaywallPriceSelected = selectedPaywalls.some(
    (paywall: any) => !paywall.id || !paywall?.web_paywall_price?.id,
  );

  const shouldDisableAddPaywall =
    hasZeroPaywallsAvailable ||
    hasSelectedMaximumPaywallsThreshold ||
    areAllPaywallPriceSelected;

  return {
    fieldArrayMethods,

    paywallsListQuery,
    selectedPaywallIds,
    allPaywallOptions,
    allPaywallPrices,
    allInAppPaywallPriceOptions,
    allWebPaywallPriceOptions,
    hasWebPaywallPrices,
    hasPaywalls,

    shouldDisableAddPaywall,
    onClickAddPaywall,

    fieldNameRoot,
  };
};
