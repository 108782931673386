import { useEffect } from "react";
import { setDateFnsOptions } from "@circle-react/config/Locales/DateFns";
import { setFlatpickrLocale } from "@circle-react/config/Locales/Flatpickr";
import { initializeBugsnag } from "@circle-react/helpers/bugsnagHelpers";
import { MainAppProviders } from "@circle-react/providers";
import type { BackendProps } from "@circle-react/providers/AppContext/index";
import { MultiContextProvider } from "@circle-react/providers/MultiContextProvider";
import { applyV2Colors } from "@circle-react/providers/Theme";
import { withErrorBoundary } from "@circle-react-uikit/ErrorBoundary";
import { SomethingWentWrong } from "@circle-react-uikit/ErrorPages";
import { HEADLESS_UI_POPOVER_ROOT } from "@circle-react-uikit/PopoverPortal";
import { MainAppContent } from "../components/MainAppContent";

initializeBugsnag();

export type MainAppProps = BackendProps;

// This component is mapped at app/javascript/controllers/react_mount_controller.js
// And rendered at app/views/shared/_react_app.html.erb
const MainApp = ({
  notice,
  alert,
  display_community_switcher = false,
  display_header = true,
  minimal_layout = false,
  ...rest
}: MainAppProps) => {
  useEffect(() => {
    applyV2Colors();
    void setDateFnsOptions();
    void setFlatpickrLocale();
  }, []);

  return (
    <MainAppProviders
      backendProps={{
        notice,
        alert,
        display_community_switcher,
        minimal_layout,
        ...rest,
      }}
    >
      <div
        id={HEADLESS_UI_POPOVER_ROOT}
        className="bg-secondary"
        data-testid="main-app-provider"
      >
        <MultiContextProvider>
          <MainAppContent
            notice={notice}
            alert={alert}
            shouldDisplayHeader={display_header}
            shouldDisplayCommunitySwitcher={display_community_switcher}
            hasMinimalLayout={minimal_layout}
          />
        </MultiContextProvider>
      </div>
    </MainAppProviders>
  );
};

export const App = withErrorBoundary(MainApp, SomethingWentWrong);
