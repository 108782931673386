import classNames from "classnames";
import { useParams } from "react-router-dom";
import { RailbarContextProvider } from "@circle-react/hooks/chatsV2";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { useMessageList } from "../hooks/useCommunityMessageList";
import { ChatWindow } from "./ChatWindow";
import { Header } from "./Header";

interface BotChatRoomInterface {
  botRoomId: number | undefined;
  isDms?: boolean;
}

export const BotChatRoom = ({
  botRoomId,
  isDms = false,
}: BotChatRoomInterface) => {
  const {
    id: chatRoomId,
  }: {
    id: any;
  } = useParams();
  const {
    messages,
    isMessagesLoading,
    createMessage,
    fetchNextPage,
    hasNextPage,
  } = useMessageList({
    id: chatRoomId || botRoomId,
  });

  if (isMessagesLoading) {
    return <Loader />;
  }
  return (
    <RailbarContextProvider>
      <div
        className={classNames("bg-primary flex h-full w-full flex-row", {
          "max-h-[calc(100vh-64px)]": !isDms,
        })}
      >
        <div className="w-full">
          <Header chatRoomId={chatRoomId || botRoomId} isDms={isDms} />
          <ChatWindow
            messages={messages}
            createMessage={createMessage}
            isDms={isDms}
            fetchNextPage={fetchNextPage}
            hasNextPage={Boolean(hasNextPage)}
          />
        </div>
        <div id="rail-bar-header" />
      </div>
    </RailbarContextProvider>
  );
};
