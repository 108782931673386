import { t } from "i18n-js";
import { usePunditUserContext } from "@circle-react/contexts";
import { Typography } from "@circle-react-uikit/Typography";
import { usePaywallsEditFormContext } from "../PaywallsEditFormProvider";

export const ViewPriceToggle = () => {
  const { isMobilePriceMode, setViewPriceMode } = usePaywallsEditFormContext();
  const {
    currentCommunitySettings: { in_app_enabled: isInAppEnabled },
  } = usePunditUserContext();

  if (!isInAppEnabled) {
    return null;
  }

  const onChange = (event: any) => {
    const newViewPriceMode = event.target.checked ? "mobile" : "web";
    setViewPriceMode(newViewPriceMode);
  };

  return (
    <div className="flex flex-col gap-y-2">
      <Typography.LabelSm weight="semibold">
        {t(
          "paywalls.lock_screen.sidebar.paywall_edit_form.view_price_for.title",
        )}
      </Typography.LabelSm>
      <label className="bg-tertiary border-hover relative inline-flex h-9 w-full cursor-pointer items-center rounded-lg border">
        <div className="z-[3] grid w-full grid-cols-2">
          <Typography.LabelMd textTransform="text-center">
            {t(
              "paywalls.lock_screen.sidebar.paywall_edit_form.view_price_for.web_option",
            )}
          </Typography.LabelMd>
          <Typography.LabelMd textTransform="text-center">
            {t(
              "paywalls.lock_screen.sidebar.paywall_edit_form.view_price_for.mobile_option",
            )}
          </Typography.LabelMd>
        </div>
        <input
          type="checkbox"
          checked={isMobilePriceMode}
          className="peer sr-only"
          onChange={onChange}
        />
        <div className="after:bg-primary absolute left-0.5 top-[0.0625rem] w-full after:absolute after:h-8 after:w-[49.375%] after:rounded-lg after:transition-all after:content-[''] peer-checked:after:translate-x-full" />
      </label>
    </div>
  );
};
