import { t } from "i18n-js";
import { StandardLayoutHeader } from "@/react/components/Layout/StandardLayoutV2/StandardLayoutHeader";
import { SpaceHeaderTitleText } from "@circle-react/components/Spaces/Header/SpaceHeaderTitle/SpaceHeaderTitleText";
import { usePunditUserContext } from "@circle-react/contexts";
import { useCommunityPaymentProcessorStatus } from "@circle-react/hooks/communityPaymentProcessor/useCommunityPaymentProcessorStatus";
import { PaywallsEmptyState } from "@circle-react-shared/Paywalls/PaywallsEmptyState";
import { Form } from "@circle-react-uikit/Form";
import { Loader } from "@circle-react-uikit/Loader";
import { AnalyticsInfo } from "../AnalyticsInfo";
import { Filters } from "../Filters";
import type { FILTERS_AVAILABLE } from "../Filters/useFilters";
import { useIsFilterBeingUsedOnTransactions } from "../Filters/useIsFilterBeingUsed";
import { ChargesList } from "./ChargesList";
import { HeaderRightContent } from "./HeaderRightContent";
import { Headline } from "./Headline";
import { Stats } from "./Stats";
import { TabWithFilters } from "./TabWithFilters";
import { TABS } from "./constants";
import { usePaywallChargesAdmin } from "./hooks/usePaywallChargesAdmin";

const filters: Array<Partial<FILTERS_AVAILABLE>> = [
  "community_member_name",
  "community_member_email",
  "community_member_id",
  "charge_status",
  "paywalls",
  "events",
  "amount",
  "created_at",
  "subscription_id",
];

const customFilters: Array<Partial<FILTERS_AVAILABLE>> = [
  "currency",
  "transaction_id",
  "invoice_processor_id",
];

export const PaywallChargesAdmin = () => {
  const {
    chargesDataByTab,
    activeFilterTab,
    setActiveFilterTab,
    setSearchKey,
    defaultValues,
    refetchAllCharges,
    resetAllChargesToFirstPage,
    showStats,
    isLoadingCharges,
  } = usePaywallChargesAdmin();

  const totalChargesCount = chargesDataByTab[TABS.ALL]?.totalCharges;
  const { currentCommunitySettings } = usePunditUserContext();
  const isFilterBeingUsed = useIsFilterBeingUsedOnTransactions();

  const isPaywallSnapshotsEnabled =
    currentCommunitySettings?.paywall_snapshots_enabled;

  const { isPaymentProcessorEnabled } = useCommunityPaymentProcessorStatus();

  const activeFilterTabValue: string = activeFilterTab
    ? String(activeFilterTab)
    : TABS.ALL;

  if (isLoadingCharges) {
    return (
      <div className="flex h-full">
        <Loader center />
      </div>
    );
  }

  if (
    !isLoadingCharges &&
    !isFilterBeingUsed &&
    (totalChargesCount === 0 || !isPaymentProcessorEnabled)
  ) {
    return (
      <PaywallsEmptyState
        isPaymentProcessorEnabled={isPaymentProcessorEnabled}
        i18nBase="settings.paywalls_admin_dashboard.charges"
        redirectToPaywalls
      />
    );
  }

  return (
    <Form
      onSubmit={({ searchKey }) => setSearchKey(searchKey)}
      defaultValues={defaultValues}
    >
      <StandardLayoutHeader
        leftContent={
          <SpaceHeaderTitleText>
            {t("settings.paywalls_admin_dashboard.charges.title")}
          </SpaceHeaderTitleText>
        }
        rightContent={
          <HeaderRightContent totalChargesCount={totalChargesCount} />
        }
        filters={
          <TabWithFilters
            setActiveFilterTab={setActiveFilterTab}
            activeFilterTab={activeFilterTabValue}
            setSearchKey={setSearchKey}
            resetAllChargesToFirstPage={resetAllChargesToFirstPage}
          />
        }
        hasBorder={false}
      />

      {!isPaywallSnapshotsEnabled && <AnalyticsInfo />}
      <Filters filterNames={filters} customFilterNames={customFilters} />
      <Headline totalChargesCount={totalChargesCount} />

      {isPaywallSnapshotsEnabled &&
        isPaymentProcessorEnabled &&
        showStats() && <Stats />}

      <ChargesList
        activeFilterTab={activeFilterTabValue}
        chargesData={chargesDataByTab[activeFilterTabValue]}
        refetchAllCharges={refetchAllCharges}
      />
    </Form>
  );
};
